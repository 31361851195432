import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const PhishingAnalysis = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="PhishingAnalysis"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">Employees are the weakest link and the last line of defense for any organization. The attackers use social engineering techniques to exploit the behavioral vulnerabilities of employees to succeed in their motives. It is important for the organizations to have a phishing program in place to continuously educate the employees about different techniques used by attackers, perform frequent testing to determine the risk to organizations, and provide detailed reports to optimize the program.</p>
                        <p className="mt-3">TEQT’s provides phishing, assessment & reporting, and training as a managed program through our PAT platform-based services.</p>
                        <p className="mt-2">PAT delivers a security awareness program custom-made to address client requirements using interactive training, simulation exercises, and branded content. TEQT PAT service is designed to educate employees on spotting and reporting suspected phishing attempts and protecting themselves & the company from cybercriminals. TEQT PAT service enables organizational decision-making through a continuous reporting mechanism.</p>
                        <h6>The features of PAT platform-based services include:</h6>
                        <ul className="circle-list">
                            <li className="mt-2"><span className="text-black">Baseline Phishing Test:</span> Solution easily accomplishes this by sending out a simulated phishing email to a random sample of personnel.</li>
                            <li className="mt-2"><span className="text-black">Personal Risk Score:</span> Risk Score enables you to act and implement security awareness mitigation plans for high-risk user groups.</li>
                            <li className="mt-2"><span className="text-black">Train Everyone:</span> this on-demand interactive, engaging exercise creates a thorough understanding of how cybercriminals operate</li>
                            <li className="mt-2"><span className="text-black">Test the Results:</span>the solution has customized templates rated by difficulty from 1 to 5 for repeat testing</li>

                        </ul>

                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query PhishingAnalysisPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

PhishingAnalysis.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default PhishingAnalysis;
